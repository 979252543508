'use client';

import { useRouter } from 'next/navigation';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { FormField } from '~/components/core/FormField';
import { useI18n } from '~/locales/client';

import { PASSWORD_REGEX } from '~/constants/regex';

import { Icon, IconName } from '~/components/core/Icon';
import Flex from '~/components/customComponents/flex/Flex';
import Text from '~/components/customComponents/texts/Text';
import { colors, spacing } from '~/utils/tailwindUtils';
import { updateHasSeenModal, updateSearchParams } from '~/utils/clientUtils';
import { postSetPassword } from '../../setup-password/requests';
import { CreationAlert } from './CreationAlert';
import Button from '~/components/customComponents/buttons/Button';

type PasswordMatcherProps = {
  email?: string;
  mode: 'create' | 'reset' | 'update';
  isFirstTimeUser: boolean;
  isSetPasswordModal?: boolean;
};

/**
 * A password matcher component that handles password input, confirmation, and submission.
 *
 * @param {string} email - email address to display
 * @param {string} mode - mode of the component (e.g. 'reset', 'set')
 * @param {boolean} isFirstTimeUser - whether the user is a first-time user
 * @param {boolean} isSetPasswordModal - whether the component is used in a set password modal
 * @return {JSX.Element} the password matcher component
 */
const PasswordMatcher = ({
  email,
  mode,
  isFirstTimeUser,
  isSetPasswordModal,
}: PasswordMatcherProps) => {
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [passwordConfirmError, setPasswordConfirmError] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);
  const [isResetButtonLoading, setIsResetButtonLoading] = useState(false);
  const [subMissionError, setSubMissionError] = useState(false);
  const t = useI18n();
  const router = useRouter();

  const copy = useMemo(() => {
    return {
      title: isFirstTimeUser
        ? t('component.password-matcher.view.title')
        : t('page.reset-password.view.title'),
      description: t('component.password-matcher.view.description'),
      label: t('component.password-matcher.view.email.label'),
      placeholder: t('component.password-matcher.view.email.placeholder'),
      confirmLabel: t('component.password-matcher.view.email.confirm.label'),
      confirmPlaceholder: t(
        'component.password-matcher.view.email.confirm.placeholder'
      ),
      errorLabel: t('component.password-matcher.view.email.error'),
      errorConfirmLabel: t(
        'component.password-matcher.view.email.confirm.error'
      ),
      submitButtonLabel: isFirstTimeUser
        ? t(`component.password-matcher.view.${mode}.submit.button`)
        : t('page.reset-password.view.button'),
    };
  }, [isFirstTimeUser, mode, t]);

  useEffect(() => {
    const isPasswordValid = PASSWORD_REGEX.test(password);
    const arePasswordsMatching = password === passwordConfirm;
    setIsButtonEnabled(isPasswordValid && arePasswordsMatching);
    setPasswordConfirmError(
      passwordConfirm.length >= 8 && !arePasswordsMatching
    );
  }, [password, passwordConfirm]);

  const onPasswordChange = useCallback(
    (value: string) => {
      setPasswordConfirmError(false);
      setPassword(value);
    },
    [setPassword]
  );
  const onPasswordConfirmChange = useCallback((value: string) => {
    setPasswordConfirmError(false);
    setPasswordConfirm(value);
  }, []);

  // Handles the submission of a new password, updating the user's password and
  // redirecting them to the welcome page if necessary.
  const onSubmitHandler = async (
    password: string,
    isFirstTimeUser: boolean,
    isSetPasswordModal?: boolean
  ) => {
    try {
      const res = await postSetPassword(password);

      if (res?.ok) {
        if (isSetPasswordModal && isFirstTimeUser) {
          updateHasSeenModal('true');
          updateSearchParams('');
        } else {
          router.push(isFirstTimeUser ? '/?welcome=true' : '/');
        }
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error in onSubmitHandler:', error);
      return false;
    }
  };

  const handleFormResetSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    setIsResetButtonLoading(true);

    const res = await onSubmitHandler(
      password,
      isFirstTimeUser,
      isSetPasswordModal
    );

    if (!res) {
      setSubMissionError(true);
      console.error('Password reset failed');
      setIsResetButtonLoading(false);
    }
  };
  return (
    <Flex className="min-w-full justify-center items-center flex-col">
      <Flex className="justify-center flex-col px-4 min-h-full max-w-100">
        <form onSubmit={handleFormResetSubmit}>
          <Flex className="mb-6 md:mb-8">
            <Flex className="flex-col">
              <h2 className="text-2xl md:text-[28px] font-bold">
                {copy.title}
              </h2>
              <Text className="text-base mt-4 font-medium text-gray-800">
                {copy.description}
              </Text>

              {email && (
                <>
                  <Flex className="px-3 py-2 border border-gray-100 rounded-full items-center gap-2 mt-2">
                    <Icon
                      size={spacing[6]}
                      name={IconName.Mail}
                      color={colors.gray[900]}
                    />
                    <Text className="text-base font-medium text-gray-900">
                      {email}
                    </Text>
                  </Flex>
                  <Text className="text-xs text-gray-800 mt-2">
                    {t('component.password-matcher.view.email.not.you')}
                  </Text>
                </>
              )}
            </Flex>
          </Flex>
          <FormField
            label={copy.label}
            name="password"
            placeholder={copy.placeholder}
            type="password"
            onChange={onPasswordChange}
            value={password}
            isInvalid={passwordConfirmError}
            errorMessage={copy.errorConfirmLabel}
          />
          <FormField
            label={copy.confirmLabel}
            name="passwordConfirm"
            placeholder={copy.confirmPlaceholder}
            type="password"
            onChange={onPasswordConfirmChange}
            value={passwordConfirm}
            isInvalid={passwordConfirmError}
            errorMessage={copy.errorConfirmLabel}
            style={{
              marginBottom: 0,
            }}
          />
          <CreationAlert password={password} showDisclaimer={isFirstTimeUser} />
          <Button
            buttonClassName="hover:bg-red-700"
            disabled={!isButtonEnabled}
            isLoading={isResetButtonLoading}
            type="submit"
            text={copy.submitButtonLabel}
          />
        </form>
      </Flex>
      {subMissionError && (
        <Text className="text-xs text-red-500 mt-4">
          {t('component.password-matcher.view.submission.failure.title')}
        </Text>
      )}
    </Flex>
  );
};

export default PasswordMatcher;
